import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../assets/Loading'
import Dropdown from '../components/Dropdown'
import {
  EmptyTableBody,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  UserCell,
} from '../components/Table'
import { HeadingMedium } from '../components/Text'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import { GhostButton } from '../components/Button'
import DownloadIcon from '../assets/Download'
import { useFlags } from 'launchdarkly-react-client-sdk'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import RedErrorCircle from '../assets/RedErrorCircle'
import { GetDefaultDateOptions } from '../utils/dates'

type User = {
  hash: string
  first_name: string
  last_name: string
  title: string
  department: string
  profile_picture: string
  email: string
  access_level: string
  frequency: number
  provisioned: boolean | null
}

enum UserFilter {
  ALL = 'All Users',
  PROVISIONED = 'All Authorized Users',
  PROVISIONED_NO_USAGE = 'Authorized with no Usage',
  USAGE_NO_PROVISION = 'Unauthorized Users with Usage',
}

export const ProductDetailsTable = () => {
  const dropdownOptions = GetDefaultDateOptions()
  const [users, setUsers] = useState<User[] | null>(null)
  const [filter, setFilter] = useState<UserFilter>(UserFilter.ALL)
  const [dateFilter, setDateFilter] = useState<string>(dropdownOptions[0][0])
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()
  const { showHris } = useFlags()

  let { product_id } = useParams()
  useEffect(() => {
    const getUsers = async () => {
      const filter = dateFilter.toLowerCase().replaceAll(' ', '_')
      const reqPath = addImpersonationParams(
        `/product/${product_id}/usage?filter=${filter}`,
        impersonate,
        reqOrgId,
        reqUserId,
        true
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch product usage`, ToastType.ERROR)
        setUsers([])
        return
      }
      const sortedUsers = (resp as User[]).sort(
        (a, b) => b.frequency - a.frequency
      )
      setUsers(sortedUsers)
    }
    getUsers()
  }, [product_id, dateFilter, reqOrgId, reqUserId, impersonate])

  if (users === null) {
    return <Loading />
  }

  const filteredUsers = users?.filter((user) => {
    switch (filter) {
      case UserFilter.PROVISIONED:
        return user.provisioned === true
      case UserFilter.PROVISIONED_NO_USAGE:
        return user.frequency === 0 && user.provisioned === true
      case UserFilter.USAGE_NO_PROVISION:
        return user.frequency > 0 && user.provisioned !== true
      case UserFilter.ALL:
      default:
        return true
    }
  })

  const downloadUsage = () => {
    if (!filteredUsers) return
    const csv = filteredUsers.map((user) => {
      return `"${user.first_name}","${user.last_name}","${user.email}","${user.frequency}"`
    })
    csv.unshift(`First Name,Last Name,Email Address,Page Visits`)

    const csvData = csv.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `diminish-usage_by_user-${new Date().toISOString()}.csv`
    a.click()
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '24px 0 16px',
        }}
      >
        <HeadingMedium color="textDefault" style={{ marginBottom: '15px' }}>
          Usage by User
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {filteredUsers.length > 0 && ` (${filteredUsers.length})`}
          </span>
        </HeadingMedium>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Dropdown
            options={[Object.values(UserFilter)]}
            defaultValue={filter}
            onSelect={(selected: string) => setFilter(selected as UserFilter)}
          />
          <Dropdown
            onSelect={(selected: string) => setDateFilter(selected)}
            options={dropdownOptions}
            defaultValue={dropdownOptions[0][0]}
          />
          <GhostButton onClick={downloadUsage} style={{ padding: '12px' }}>
            <DownloadIcon />
          </GhostButton>
        </div>
      </div>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell w="40%">User</TableCell>
            {showHris ? (
              <>
                <TableCell>Title</TableCell>
                <TableCell>Department</TableCell>
              </>
            ) : (
              <></>
            )}
            <TableCell>Access Level</TableCell>
            <TableCell>Visits</TableCell>
            {users.some((user) => user.provisioned !== null) && (
              <TableCell mw="100px">Authorized</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.length === 0 ? (
            <EmptyTableBody colSpan={9} />
          ) : (
            filteredUsers.map((user, i) => (
              <TableRow key={i}>
                <UserCell
                  hash={user.hash}
                  first_name={user.first_name}
                  last_name={user.last_name}
                  email={user.email}
                  profile_picture={user.profile_picture}
                />
                {showHris ? (
                  <>
                    <TableCell>{user.title || '-'}</TableCell>
                    <TableCell>{user.department || '-'}</TableCell>
                  </>
                ) : (
                  <></>
                )}
                <TableCell>{user.access_level}</TableCell>
                <TableCell>{user.frequency}</TableCell>
                {users.some((user) => user.provisioned !== null) && (
                  <ProvisionedCell provisioned={user.provisioned} />
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </TableWrapper>
    </>
  )
}

const ProvisionedCell = ({ provisioned }: { provisioned: boolean | null }) => {
  switch (provisioned) {
    case true:
      return (
        <TableCell style={{ maxWidth: '100px' }}>
          <GreenCheckCircle /> Yes
        </TableCell>
      )
    case false:
    case null:
    default:
      return (
        <TableCell>
          <RedErrorCircle /> No
        </TableCell>
      )
  }
}
