import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HeadingLarge, HeadingMedium, HeadingSmall } from '../components/Text'
import OpenNewTab from '../assets/OpenNewTab'
import GroupPeople from '../assets/GroupPeople'
import BarChart from '../assets/BarChart'
import Page from '../assets/Page'
import AccountBox from '../assets/AccountBox'
import AutoRenew from '../assets/AutoRenew'
import CalendarEvent from '../assets/CalendarEvent'

import theme from '../Theme'
import Button from '../components/Button'
import { IconWrapper } from '../components/Profile'
import Divider from '../components/Divider'
import {
  currencyFormat,
  getThisMonthShort,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import { ProductDetailsTable } from './ProductDetailsTable'
import { SVGType } from '../assets/SVGWrapper'
import { useParams } from 'react-router-dom'
import { GraphWrapper, StyledDualBarChart } from './MainGraph'
import BackToPage from '../components/BackToPage'
import Loading from '../assets/Loading'
import BarChartEmpty from '../assets/BarChartEmpty'
import { LatestInvoicesTable } from './LatestInvoicesTable'
import useImpersonation from '../hooks/useImpersonation'
import { ContractModal, ContractModalType } from '../Contracts/ContractModal'

const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 20px;
`

const LogoWrapper = styled('div')({
  width: '72px',
  height: '72px',
  padding: '2px',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  '& > img': {
    borderRadius: '4px',
    width: '72px',
    height: '72px',
  },
})

const TextIconWrapper = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '4px',
})

const ContractCardWrapper = styled('div')({
  display: 'flex',
  gap: '11px',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '8px',
  backgroundColor: theme.color.white,
  padding: '16px 24px',
  marginBottom: '33px',
  flexGrow: 1,
})

export const ContractCard = ({
  Icon,
  title,
  value,
}: {
  Icon: SVGType
  title: JSX.Element | string
  value: JSX.Element | string
}) => (
  <ContractCardWrapper>
    <IconWrapper Icon={Icon} />
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <HeadingSmall>{title}</HeadingSmall>
      <HeadingMedium color="textDefault">{value}</HeadingMedium>
    </div>
  </ContractCardWrapper>
)

export type ProductDetailsType = {
  id: number
  name: string
  category: string
  url: string
  logo_url: string
  num_users: number
  prev_num_users: number
  num_visits: number
  prev_num_visits: number
  cur_spend: number
  annual_spend: number
}

const ProductDetails = () => {
  const [contractModal, setContractModal] = useState(false)
  const [productDetails, setProductDetails] = useState<
    ProductDetailsType | null | undefined
  >(undefined)
  const { product_id } = useParams()
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (product_id === undefined) {
      return
    }
    if (productDetails !== undefined) {
      return
    }

    const getApplication = async () => {
      const reqPath = addImpersonationParams(
        `/product/${product_id}`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch product details`, ToastType.ERROR)
        setProductDetails(null)
        return
      }
      setProductDetails(resp)
    }
    getApplication()
  }, [product_id, productDetails, reqOrgId, reqUserId, impersonate])

  if (productDetails === undefined) {
    return <Loading large />
  }
  if (productDetails === null) {
    return (
      // TODO: implement better Not found page
      <>
        <BackToPage path={'/'} pageName={'Overview'} />
        <HeadingLarge style={{ marginTop: '20px', textTransform: 'none' }}>
          Sorry, the product you are looking could not be found
        </HeadingLarge>
      </>
    )
  }

  return (
    <>
      <BackToPage path={'/'} pageName={'Overview'} />
      <HeadingWrapper
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
          <LogoWrapper>
            <img alt={productDetails.name} src={productDetails.logo_url} />
          </LogoWrapper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
            }}
          >
            <TextIconWrapper
              href={`https://${productDetails.url}`}
              target="_blank"
            >
              <HeadingLarge>{productDetails.name}</HeadingLarge>
              <OpenNewTab style={{ color: theme.color.textDefault }} />
            </TextIconWrapper>
            <HeadingMedium>{productDetails.category}</HeadingMedium>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Button onClick={() => setContractModal(true)}>Add contract</Button>
        </div>
      </HeadingWrapper>
      <ContractDetails />
      <SpendOverviewCards
        month_spend={productDetails.cur_spend}
        annual_spend={productDetails.annual_spend}
        active_users={productDetails.num_users}
        prev_active_users={productDetails.prev_num_users}
        total_visits={productDetails.num_visits}
        prev_total_visits={productDetails.prev_num_visits}
      />
      <div
        style={{
          display: 'flex',
          gap: '29px',
          marginTop: '24px',
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <MainGraph
            product_id={product_id!}
            productName={productDetails.name}
          />
          <ProductDetailsTable />
          <LatestInvoicesTable />
        </div>
      </div>
      {contractModal && (
        <>
          <ContractModal
            onDismiss={() => setContractModal(false)}
            contractEdit={{
              application_name: productDetails.name,
              owner_email: '',
              plan_name: '',
              contract_value: 0,
              start_date: '',
              end_date: '',
              num_seats: 0,
              billing_cycle: '',
              document_type: [],
            }}
            modalType={undefined}
            contractEditCallback={() => {}}
          />
        </>
      )}
    </>
  )
}

type Contract = {
  plan_name: string
  billing_cycle: string
  pricing_model: string
  contract_value: number
  end_date: string
}

export const ContractDetails = () => {
  const [contractDetails, setContractDetails] = useState<Contract | undefined>()
  const { product_id } = useParams()
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (contractDetails) {
      return
    }

    const getContract = async () => {
      const reqPath = addImpersonationParams(
        `/contract/${product_id}`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch contract details`, ToastType.ERROR)
        return
      }
      setContractDetails(resp)
    }
    getContract()
  }, [contractDetails, product_id, reqOrgId, reqUserId, impersonate])

  if (!contractDetails) {
    return <></>
  }
  if (
    !contractDetails.plan_name &&
    !contractDetails.billing_cycle &&
    !contractDetails.pricing_model &&
    !contractDetails.contract_value &&
    !contractDetails.end_date
  ) {
    return <></>
  }

  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <ContractCard
        Icon={Page}
        title={'Plan'}
        value={contractDetails.plan_name || '-'}
      />
      <ContractCard
        Icon={AccountBox}
        title={'Pricing Model'}
        value={contractDetails.pricing_model || '-'}
      />
      <ContractCard
        Icon={AutoRenew}
        title={'Billing Cycle'}
        value={contractDetails.billing_cycle || '-'}
      />
      <ContractCard
        Icon={AutoRenew}
        title={'Last Payment'}
        value={`$${currencyFormat(contractDetails.contract_value)}`}
      />
      <ContractCard
        Icon={CalendarEvent}
        title={'Renewal Date'}
        value={contractDetails.end_date || '-'}
      />
    </div>
  )
}

type GraphData = {
  [d: string]: {
    usage: number
    spend: number
    users: number
  }
}

const MainGraph = ({
  product_id,
  productName,
}: {
  product_id: string
  productName: string
}) => {
  const [dataset, setDataset] = useState<GraphData | null | undefined>(
    undefined
  )
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (dataset !== undefined) {
      return
    }

    const getUsage = async () => {
      const reqPath = addImpersonationParams(
        `/product/${product_id}/summary`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch usage/spend history`, ToastType.ERROR)
        setDataset(null)
        return
      }
      if (Object.keys(resp.history).length === 0) {
        setDataset(null)
        return
      }
      setDataset(resp.history)
    }
    getUsage()
  }, [product_id, dataset, reqOrgId, reqUserId, impersonate])

  if (dataset === undefined) {
    return (
      <GraphWrapper>
        <Loading />
      </GraphWrapper>
    )
  }
  if (dataset === null) {
    return (
      <EmptyGraphWrapper>
        <BarChartEmpty />
        <HeadingMedium>
          No data found for {productName} at this moment
        </HeadingMedium>
      </EmptyGraphWrapper>
    )
  }

  const dataset1: { legend: string; data: { [s: string]: number } } = {
    legend: 'Usage',
    data: {},
  }
  const dataset2: { legend: string; data: { [s: string]: number } } = {
    legend: 'Spend',
    data: {},
  }
  const dataset3: { legend: string; data: { [s: string]: number } } = {
    legend: 'Users',
    data: {},
  }
  for (const [k, v] of Object.entries(dataset)) {
    dataset1.data[k] = v.usage
    dataset2.data[k] = v.spend
    dataset3.data[k] = v.users
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {dataset && Object.keys(dataset).length > 0 && (
        <StyledDualBarChart
          title="Usage/Spending Overview"
          subTitle="Last 12 months"
          dataset1={dataset1}
          dataset2={dataset2}
          dataset3={dataset3}
        />
      )}
    </div>
  )
}

const TotalSavingsWrapper = styled('div')({
  borderRadius: '8px',
  border: `1px solid ${theme.color.border}`,
  backgroundColor: theme.color.white,
  display: 'flex',
  alignItems: 'center',
  gap: '44px',
  padding: '24px',
  height: '75px',
  width: '100%',
})

const SpendOverviewCards = ({
  annual_spend,
  month_spend,
  active_users,
  prev_active_users,
  total_visits,
  prev_total_visits,
}: {
  annual_spend: number
  month_spend: number
  active_users: number
  prev_active_users: number
  total_visits: number
  prev_total_visits: number
}) => {
  const getUserDiff = (before: number, now: number) => {
    if (before < now) {
      return `(${Math.abs(before - now)} new user${
        now - before > 1 ? 's' : ''
      } this month)`
    }
    if (before > now) {
      return `(${Math.abs(before - now)} less user${
        before - now > 1 ? 's' : ''
      } this month)`
    }
    return '(same as last month)'
  }
  const getVisitsDiff = (before: number, now: number) => {
    if (before === 0) {
      return `(more visits this month)`
    }
    if (before < now) {
      return `(${Math.round((now / before - 1) * 100)}% more visit this month)`
    }
    if (before > now) {
      return `(${Math.round((1 - now / before) * 100)}% less visit this month)`
    }
    return '(same as last month)'
  }
  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <TotalSavingsWrapper style={{ flexGrow: 2, flexShrink: 2 }}>
        <div style={{ flexGrow: 3 }}>
          <HeadingSmall color={'textSubdued'}>
            This Month Spend{' '}
            <span style={{ fontSize: '14px', fontWeight: 500 }}>
              ({getThisMonthShort()})
            </span>
          </HeadingSmall>
          <HeadingLarge color={'textPurple'}>
            ${currencyFormat(month_spend)}
          </HeadingLarge>
        </div>

        <Divider vertical />
        <div style={{ flexGrow: 3 }}>
          <HeadingSmall color={'textSubdued'}>Annual Spend</HeadingSmall>
          <HeadingLarge color={'textDefault'}>
            ${currencyFormat(annual_spend)}
          </HeadingLarge>
        </div>
      </TotalSavingsWrapper>
      <TotalSavingsWrapper style={{ flexGrow: 1, flexShrink: 3 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            minWidth: '320px',
            flexGrow: 1,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '15px' }}>
              <IconWrapper Icon={GroupPeople} />
              <HeadingSmall>Active Users</HeadingSmall>
            </div>
            <HeadingSmall color="textDefault" style={{ textAlign: 'right' }}>
              {active_users}{' '}
              <span
                style={{
                  color: theme.color.textSubdued,
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {getUserDiff(prev_active_users, active_users)}
              </span>
            </HeadingSmall>
          </div>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '15px' }}>
              <IconWrapper Icon={BarChart} />
              <HeadingSmall>Total Visits</HeadingSmall>
            </div>
            <HeadingSmall color="textDefault">
              {total_visits}{' '}
              <span
                style={{
                  color: theme.color.textSubdued,
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {getVisitsDiff(prev_total_visits, total_visits)}
              </span>
            </HeadingSmall>
          </div>
        </div>
      </TotalSavingsWrapper>
    </div>
  )
}

const EmptyGraphWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.color.borderDisabled};
  border-radius: 8px;
  height: 414px;
  min-width: 803px;
  background-color: ${theme.color.white};
`

export default ProductDetails
