import React, { CSSProperties } from 'react'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import Error from '../assets/Error'
import Warning from '../assets/Warning'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import useImpersonation from '../hooks/useImpersonation'

export const completeIroncladSetup = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  variation: string,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  const params = new URLSearchParams(
    window.location.href.substring(window.location.href.indexOf('?'))
  )
  // clear query string without refreshing the page. prevents double requests by state change
  window.history.replaceState({}, '', '/integrations')

  if (params.get('error')) {
    setModal(IntgModal.IroncladFail)
    return
  }
  const ironcladPath = addImpersonationParams(
    `/integrations/${variation}`,
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )

  const resp = await backendRequest(ironcladPath, {
    method: 'POST',
    body: JSON.stringify({
      code: params.get('code'),
      state: params.get('state'),
      variation: variation,
    }),
  })
  if (resp.error) {
    setModal(IntgModal.IroncladFail)
    return
  }
  setModal(IntgModal.IroncladSuccess)
}

const redirectToIroncladMainAuth = async () => {
  const clientID = process.env.REACT_APP_IRONCLAD_MAIN_CLIENT_ID
  const redirectURI = process.env.REACT_APP_IRONCLAD_MAIN_REDIRECT_URI
  const state = `diminish_ironclad_Q7EHW`
  window.location.href = `https://ironcladapp.com/oauth/authorize?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}&scope=public.records.readRecords public.records.readSchemas public.records.readAttachments public.records.readSmartImportRecords&state=${state}`
}

const redirectToIroncladEUAuth = async () => {
  const clientID = process.env.REACT_APP_IRONCLAD_EU_CLIENT_ID
  const redirectURI = process.env.REACT_APP_IRONCLAD_EU_REDIRECT_URI
  const state = `diminish_ironclad_Q7EHW`
  window.location.href = `https://eu1.ironcladapp.com/oauth/authorize?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}&scope=public.records.readRecords public.records.readSchemas public.records.readAttachments public.records.readSmartImportRecords&state=${state}`
}

const removeIroncladIntegration = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  variation: string,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  const ironcladPath = addImpersonationParams(
    `/integrations/${variation}`,
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )
  const resp = await backendRequest(ironcladPath, { method: 'DELETE' })
  if (resp.error) {
    setModal(IntgModal.IroncladFail)
    return
  }
  setModal(0)
}

export const IroncladModalHandler = ({
  modal,
  onDismiss,
  setModal,
  variation,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
  variation: string
}) => {
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()
  switch (modal) {
    case IntgModal.IroncladRedirect:
      return <IroncladRedirectModal onDismiss={onDismiss} />
    case IntgModal.IroncladSuccess:
      return <IroncladSuccessModal onDismiss={onDismiss} />
    case IntgModal.IroncladFail:
      return <IroncladFailModal onDismiss={onDismiss} />
    case IntgModal.IroncladRemove:
      return (
        <IroncladRemoveModal
          onDismiss={onDismiss}
          onClick={() => {
            removeIroncladIntegration(
              setModal,
              variation,
              impersonate,
              reqOrgId,
              reqUserId
            )
          }}
        />
      )
    default:
      return <></>
  }
}

export const IroncladRedirectModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <IroncladModalRedirect
      onDismiss={onDismiss}
      onClickMain={redirectToIroncladMainAuth}
      onClickEU={redirectToIroncladEUAuth}
      canCancel={true}
      heading="Redirecting to Ironclad"
      body={
        'You will be redirected to Ironclad to complete the next step for integrating with Diminish. Select the URL where your Ironclad instance is hosted.'
      }
      buttonTxt="Continue"
      color="textPurple"
    />
  )
}

export const IroncladSuccessModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <IroncladModal
      onDismiss={onDismiss}
      onClick={onDismiss}
      canCancel={false}
      heading="Congratulations"
      body={
        'You have successfully integrated with Ironclad. Contracts will be populated shortly.'
      }
      buttonTxt="Ok"
      Icon={GreenCheckCircle}
      color="successGreen"
    />
  )
}

export const IroncladFailModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <IroncladModal
      onDismiss={onDismiss}
      onClick={onDismiss}
      canCancel={false}
      heading="Something went wrong"
      body={'Please try again later, or to contact support.'}
      buttonTxt="Ok"
      Icon={Error}
      color="errorRed"
    />
  )
}

export const IroncladRemoveModal = ({
  onDismiss,
  onClick,
}: {
  onDismiss: () => void
  onClick: () => void
}) => {
  return (
    <IroncladModal
      onDismiss={onDismiss}
      onClick={onClick}
      canCancel={true}
      heading="Remove Integration"
      body={
        'Are you sure you want to remove this integration? By removing your Ironclad integration, contracts will be removed from the dashboard.'
      }
      buttonTxt="Remove"
      Icon={Warning}
      color="errorRed"
    />
  )
}
const IroncladModal = ({
  onDismiss,
  onClick,
  canCancel,
  heading,
  body,
  buttonTxt,
  Icon,
  color,
}: {
  onDismiss: () => void
  onClick: () => Promise<void> | void
  canCancel: boolean
  heading: string
  body: string
  buttonTxt: string
  Icon?: ({ style }: { style: CSSProperties }) => JSX.Element
  color: keyof typeof theme.color
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '350px', borderColor: theme.color[color] }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        {Icon ? <Icon style={{ height: '24px', width: '24px' }} /> : <></>}
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      <HeadingSmallest>{body}</HeadingSmallest>
      <Divider />
      <ButtonWrapper>
        {canCancel && (
          <GhostButton
            onClick={onDismiss}
            style={{ padding: '14px 24px', borderColor: theme.color[color] }}
          >
            Cancel
          </GhostButton>
        )}
        <Button
          onClick={onClick}
          style={{
            backgroundColor: theme.color[color],
            border: theme.color[color],
          }}
        >
          {buttonTxt}
        </Button>
      </ButtonWrapper>
    </div>
  </Modal>
)

const IroncladModalRedirect = ({
  onDismiss,
  onClickMain,
  onClickEU,
  canCancel,
  heading,
  body,
  buttonTxt,
  Icon,
  color,
}: {
  onDismiss: () => void
  onClickMain: () => Promise<void> | void
  onClickEU: () => Promise<void> | void
  canCancel: boolean
  heading: string
  body: string
  buttonTxt: string
  Icon?: ({ style }: { style: CSSProperties }) => JSX.Element
  color: keyof typeof theme.color
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '350px', borderColor: theme.color[color] }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        {Icon ? <Icon style={{ height: '24px', width: '24px' }} /> : <></>}
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      <HeadingSmallest>{body}</HeadingSmallest>
      <Divider />
      <ButtonWrapper>
        {canCancel && (
          <GhostButton
            onClick={onDismiss}
            style={{ padding: '6px 10px', borderColor: theme.color[color] }}
          >
            Cancel
          </GhostButton>
        )}
        <Button
          onClick={onClickMain}
          style={{
            backgroundColor: theme.color[color],
            border: theme.color[color],
            padding: '2px 4px',
          }}
        >
          ironcladapp.com
        </Button>

        <Button
          onClick={onClickEU}
          style={{
            backgroundColor: theme.color[color],
            border: theme.color[color],
            padding: '2px 4px',
          }}
        >
          eu1.ironcladapp.com
        </Button>
      </ButtonWrapper>
    </div>
  </Modal>
)
