import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import ContractsEmpty from '../assets/ContractsEmpty'
import Loading from '../assets/Loading'
import Button from '../components/Button'
import {
  Badge,
  ContractEdit,
  ContractModal,
  ContractModalType,
} from './ContractModal'
import {
  ApplicationCell,
  ArrowDownCell,
  OwnerCell,
  SortArrow,
  SortOrder,
  SortableHeader,
  SubTable,
  SubTableCell,
  SubTableHead,
  SubTableHeading,
  SubTableRow,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  TitleCell,
} from '../components/Table'
import {
  HeadingMedium,
  HeadingSmall,
  HeadingSmallest,
} from '../components/Text'
import theme from '../Theme'
import {
  currencyFormat,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import { UserType } from '../Users'
import { PDFPreviewModal } from '../Overview/LatestInvoicesTable'
import useImpersonation from '../hooks/useImpersonation'
import { Application } from '../Overview/ApplicationsTable'
import Dropdown from '../components/Dropdown'
import Paper from '../assets/Paper'
import Archive from '../assets/Archive'
import Pencil from '../assets/Pencil'

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  position: 'sticky',
  top: '77px',
  background: theme.color.backgroudDefault,
  zIndex: 5,
  alignItems: 'flex-end',
  width: '100%',
  padding: '24px 0',
})

type Contract = {
  id: number
  saas_id: number
  type: string[]
  name: string
  logo_url: string
  plan_name: string
  owner: string
  owner_hash: string
  procurement_owner: string
  profile_picture: string
  pricing_model: string
  frequency: number
  num_users: number
  billing_cycle: string
  contract_value: number
  issue_date: string
  start_date: string
  end_date: string
  paid_licenses: string
  archived_date: string
  has_preview: boolean
  receiver_email: string
}

export type ContractList = { [saas_id: number]: Contract[] }

export type Contracts = {
  active_contracts: ContractList
  archived_contracts: ContractList
  num_active: number
  num_archived: number
}

type CustomInbox = {
  email: string
}

const HeadingWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '16px',
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  backgroundColor: theme.color.white,
  padding: '18px 24px',
})

const EmailWrapper = styled('span')({
  fontWeight: 'bold',
})

const ActiveContractsPage = () => {
  const [contracts, setContracts] = useState<Contracts | null>()
  const [contractEdit, setContractEdit] = useState<ContractEdit | null>(null)
  const [paidTools, setPaidTools] = useState<Application[] | null>()
  const [inboxes, setInboxes] = useState<CustomInbox[]>()
  const [showModal, setShowModal] = useState(false)
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    if (contracts) {
      return
    }
    const getContracts = async () => {
      const reqPath = addImpersonationParams(
        `/contracts`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp === null || resp.error) {
        showToast(
          `Failed to fetch contracts. Please try again later.`,
          ToastType.ERROR
        )
        setContracts(null)
        return
      }
      setContracts(resp)
    }
    getContracts()
  }, [contracts, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (paidTools) {
      return
    }
    const getPaidTools = async () => {
      const reqPath = addImpersonationParams(
        `/expenses/products`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp === null || resp.error) {
        showToast(
          `Failed to fetch paid applications. Please try again later.`,
          ToastType.ERROR
        )
        setPaidTools(null)
        return
      }
      setPaidTools(resp)
    }
    getPaidTools()
  }, [paidTools, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (inboxes) {
      return
    }
    const getInboxes = async () => {
      const reqPath = addImpersonationParams(
        `/custom_inbox`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp === null || resp.error) {
        setInboxes([])
        return
      }
      setInboxes(resp)
    }
    getInboxes()
  }, [inboxes, reqOrgId, reqUserId, impersonate])

  if (contracts === undefined) {
    return <Loading large />
  }

  const onDismiss = () => {
    setShowModal(false)
  }

  const handleContractEdit = (contract_id: number) => {
    let contract = null
    for (const [, value] of Object.entries(contracts?.active_contracts || [])) {
      contract = value.find((c) => c.id === contract_id)
      if (contract) {
        break
      }
    }
    if (contract) {
      setContractEdit({
        contract_id: contract.id,
        application_name: contract.name,
        owner_email: atob(contract.owner_hash),
        plan_name: contract.plan_name,
        billing_cycle: contract.billing_cycle,
        contract_value: contract.contract_value,
        start_date: contract.start_date,
        end_date: contract.end_date,
        num_seats: Number(contract.paid_licenses),
        document_type: contract.type,
      })
      setShowModal(true)
    }
  }

  const contractEditCallback = (contract: ContractEdit) => {
    setContracts(null)
  }

  return (
    <>
      <ForwardInbox
        inboxes={inboxes}
        addContract={() => {
          setShowModal(true)
          setContractEdit(null)
        }}
      />
      <ActiveContracts
        onClick={() => setShowModal(true)}
        handleContractEdit={handleContractEdit}
        contractList={contracts?.active_contracts || []}
        paidTools={paidTools || []}
        numContracts={contracts?.num_active || 0}
      />
      {showModal && (
        <ContractModal
          modalType={contractEdit ? ContractModalType.EDIT : undefined}
          contractEdit={contractEdit}
          onDismiss={onDismiss}
          contractEditCallback={contractEditCallback}
        />
      )}
    </>
  )
}

const ForwardInbox = ({
  inboxes,
  addContract,
}: {
  inboxes: CustomInbox[] | undefined
  addContract: () => void
}) => (
  <HeadingWrapper>
    <HeadingMedium color="textDefault">Add Contract</HeadingMedium>
    {inboxes === undefined || inboxes.length === 0 ? (
      <HeadingSmallest>
        Please contact the Diminish team for your forwarding email inbox to send
        invoices and contracts to our platform. The files will be linked to
        applications we are tracking for you on our platform.
      </HeadingSmallest>
    ) : (
      <HeadingSmallest>
        Enhance your contract management. Whether through document upload or
        manual entry. Additionally, forward your invoices and contracts to
        <EmailWrapper> {inboxes[0].email}</EmailWrapper>. The files will be
        linked to applications we are tracking for you on our platform.
      </HeadingSmallest>
    )}
    <Button onClick={() => addContract()}>Add a Contract</Button>
  </HeadingWrapper>
)

const EmptyStateWrapper = styled('div')({
  borderTop: `1px solid ${theme.color.border}`,
  borderBottom: `1px solid ${theme.color.border}`,
  margin: '15px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
  gap: '8px',
})

const ActiveContractEmptyState = ({ onClick }: { onClick: () => void }) => (
  <TableRow>
    <TableCell colSpan={15}>
      <EmptyStateWrapper>
        <ContractsEmpty />
        <HeadingSmall color="textDefault">
          This is where you see your Contracts
        </HeadingSmall>
        <Button style={{ marginTop: '16px' }} onClick={onClick}>
          Add Contracts
        </Button>
      </EmptyStateWrapper>
    </TableCell>
  </TableRow>
)

enum TableSort {
  APPLICATION = 'application',
  PRICING_MODEL = 'pricing_model',
}

enum ContractFilter {
  ALL = 'All Contracts',
  MISSING = 'Missing Contracts',
  EXISTING = 'Existing Contracts',
}

export const ActiveContracts = ({
  contractList,
  paidTools,
  numContracts,
  onClick,
  handleContractEdit,
}: {
  contractList: { [saas_id: number]: Contract[] }
  paidTools: Application[]
  numContracts: number
  onClick: () => void
  handleContractEdit: (contract_id: number) => void
}) => {
  const [tools, setTools] = useState<Application[]>(paidTools)
  const [contracts, setContracts] = useState<{ [saas_id: number]: Contract[] }>(
    contractList
  )
  const [users, setUsers] = useState<UserType[] | undefined>(undefined)
  const [sortAttr, setSortAttr] = useState<TableSort>(TableSort.APPLICATION)
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC)
  const [showPDFPreviewModal, setShowPDFPreviewModal] = useState(false)
  const [filter, setFilter] = useState<ContractFilter>(ContractFilter.EXISTING)
  const [invoiceURL, setInvoiceURL] = useState('')
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  const contractCount = {
    [ContractFilter.ALL]: numContracts,
    [ContractFilter.EXISTING]: numContracts,
    [ContractFilter.MISSING]: paidTools.reduce(
      (acc, tool) => acc + (contracts[tool.id] === undefined ? 1 : 0),
      0
    ),
  }

  const sort = useCallback(() => {
    if (!tools) {
      return []
    }
    const sorted = [...tools]
    switch (sortAttr) {
      case TableSort.PRICING_MODEL:
        sorted.sort((a, b) => a.pricing_model.localeCompare(b.pricing_model))
        break
      case TableSort.APPLICATION:
      default:
        sorted.sort((a, b) => a.name.localeCompare(b.name))
    }
    sortOrder === SortOrder.DESC && sorted.reverse()
    return sorted
  }, [sortAttr, sortOrder, tools])

  useEffect(() => {
    if (!contractList || Object.keys(contractList).length === 0) {
      return
    }
    setContracts(contractList)
  }, [contractList])
  useEffect(() => {
    setTools(sort())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAttr, sortOrder])

  const onSortChange = (selected: TableSort) => {
    if (!contracts) return
    if (sortAttr === selected) {
      setSortOrder(sortOrder * -1)
    } else {
      setSortOrder(SortOrder.ASC)
      setSortAttr(selected)
    }
  }

  useEffect(() => {
    const getUsers = async () => {
      const reqPath = addImpersonationParams(
        `/user_names`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(
          `Failed to fetch users. Please try again later.`,
          ToastType.ERROR
        )
        setUsers([])
      }
      setUsers(resp)
    }
    getUsers()
  }, [reqOrgId, reqUserId, impersonate])

  const handleArchive = async (contract_id: number) => {
    const contractArchivePath = addImpersonationParams(
      `/contracts/archive`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )
    const resp = await backendRequest(contractArchivePath, {
      method: 'POST',
      body: JSON.stringify({
        contract_id,
      }),
    })

    if (resp.error) {
      showToast(
        `Failed to archive contract. Please try again later.`,
        ToastType.ERROR
      )
      return
    }
    showToast(`Contract successfully archived`, ToastType.SUCCESS)
    const updatedContracts = { ...contracts }
    for (const [key, value] of Object.entries(updatedContracts)) {
      updatedContracts[parseInt(key)] = value.filter(
        (contract) => contract.id !== contract_id
      )
    }
    setContracts(updatedContracts)
  }

  const handlePDFPreview = async (contract_id: number) => {
    setInvoiceURL('')
    setShowPDFPreviewModal(true)

    const reqPath = addImpersonationParams(
      `/contracts/preview/${contract_id}`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(reqPath)
    if (resp.error) {
      showToast(`Failed to fetch contract preview`, ToastType.ERROR)
      return
    }

    const { preview_url } = resp
    setInvoiceURL(preview_url)
  }

  return (
    <>
      <ButtonWrapper>
        <HeadingMedium color="textDefault">
          {filter}
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {` (${contractCount[filter]})`}
          </span>
        </HeadingMedium>
        <Dropdown
          defaultValue={ContractFilter.EXISTING}
          options={[
            ContractFilter.ALL,
            ContractFilter.EXISTING,
            ContractFilter.MISSING,
          ]}
          onSelect={(selected: string) => {
            setFilter(selected as ContractFilter)
          }}
        />
      </ButtonWrapper>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow
            style={{
              position: 'sticky',
              top: '175px',
              background: theme.color.backgroudDefault,
              zIndex: 3,
            }}
          >
            <TableCell mw="5px"></TableCell>
            <SortableHeader
              w="170px"
              isSorted={sortAttr === TableSort.APPLICATION}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.APPLICATION)}
            >
              Application
            </SortableHeader>
            <TableCell>Agreements & InfoSec</TableCell>
            <TableCell w="200px">App Owner</TableCell>
            <TableCell w="200px">Procurement Owner</TableCell>
            <TableCell>Pricing Model</TableCell>
            <TableCell>Contract Value</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell rightAlign={true} mw="110px">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(contracts).length === 0 ? (
            <ActiveContractEmptyState onClick={onClick} />
          ) : (
            <ActiveContractRows
              contracts={contracts}
              tools={paidTools}
              users={users || []}
              filter={filter}
              handleContractEdit={handleContractEdit}
              handleArchive={handleArchive}
              handlePDFPreview={handlePDFPreview}
            />
          )}
        </TableBody>
      </TableWrapper>
      {showPDFPreviewModal !== false && (
        <PDFPreviewModal
          onDismiss={() => setShowPDFPreviewModal(false)}
          invoice_url={invoiceURL}
        />
      )}
      <Tooltip anchorSelect=".edit-icon" place="top" style={{ zIndex: 10 }}>
        Edit Contract
      </Tooltip>
      <Tooltip anchorSelect=".preview-icon" place="top" style={{ zIndex: 10 }}>
        Preview Contract
      </Tooltip>
      <Tooltip anchorSelect=".archive-icon" place="top" style={{ zIndex: 10 }}>
        Archive Contract
      </Tooltip>
    </>
  )
}

const ActiveContractRows = ({
  contracts,
  tools,
  users,
  filter,
  handleContractEdit,
  handleArchive,
  handlePDFPreview,
}: {
  contracts: { [saas_id: number]: Contract[] }
  tools: Application[]
  users: UserType[]
  filter: ContractFilter
  handleContractEdit: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
  handlePDFPreview: (contract_id: number) => void
}) => {
  if (Object.keys(contracts).length === 0 && tools.length === 0) {
    return <ActiveContractEmptyState onClick={() => {}} />
  }

  const allTools: {
    [saas_id: number]: { app?: Application; appContracts?: Contract[] }
  } = {}
  for (let tool of tools) {
    allTools[tool.id] = { app: tool }
  }
  for (const [key, value] of Object.entries(contracts)) {
    if (allTools[parseInt(key)] === undefined) {
      allTools[parseInt(key)] = { appContracts: value }
    } else {
      allTools[parseInt(key)].appContracts = value
    }
  }

  return Object.entries(allTools).map(([key, { app, appContracts }], i) =>
    filter === ContractFilter.EXISTING &&
    appContracts === undefined ? null : filter === ContractFilter.MISSING &&
      appContracts !== undefined ? null : (
      <ActiveContractRow
        key={i}
        contracts={contracts[parseInt(key)] || []}
        app={app}
        users={users}
        handleContractEdit={handleContractEdit}
        handleArchive={handleArchive}
        handlePDFPreview={handlePDFPreview}
      />
    )
  )
}

const ActiveContractRow = ({
  contracts,
  app,
  users,
  handleContractEdit,
  handleArchive,
  handlePDFPreview,
}: {
  contracts: Contract[]
  app: Application | undefined
  users: UserType[]
  handleContractEdit: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
  handlePDFPreview: (contractID: number) => void
}) => {
  const [showDetails, setShowDetails] = useState(false)

  if (!app && !contracts.length) {
    return null
  }

  const saas_id = contracts.length ? contracts[0].saas_id : app!.id
  const contract = contracts ? contracts[0] : undefined
  const getAgreements = (contract: Contract) => {
    return contract.type.some((type) =>
      ['MSA/TOU', 'DPA', '(M)NDA', 'InfoSec'].includes(type)
    )
  }

  const hasDocType = (type: string) =>
    !contracts.every((c) => c.type && c.type.every((t) => t !== type))

  return (
    <>
      <TableRow alert={contracts.length === 0}>
        <ArrowDownCell
          onClick={() => setShowDetails(!showDetails)}
          open={showDetails}
        />
        <ApplicationCell
          id={saas_id}
          logo={app?.logo || contract?.logo_url}
          name={app?.name || contract?.name || ''}
          number={contracts.length || 0}
          error={contracts.length === 0 ? 'Missing Contract' : undefined}
        />
        <TableCell>
          <Badge disabled={!hasDocType('MSA/TOU')}>MSA/TOU</Badge>
          <Badge disabled={!hasDocType('DPA')}>DPA</Badge>
          <Badge disabled={!hasDocType('(M)NDA')}>(M)NDA</Badge>
          <Badge disabled={!hasDocType('InfoSec')}>InfoSec</Badge>
        </TableCell>
        <OwnerCell
          appId={saas_id}
          options={users}
          defaulOption={app?.app_owner || contract?.owner || ''}
          ownerType="app"
        />
        <OwnerCell
          appId={saas_id}
          options={users}
          defaulOption={
            app?.procurement_owner || contract?.procurement_owner || ''
          }
          ownerType="procurement"
        />
        <TableCell>
          {app?.pricing_model || contract?.pricing_model || '-'}
        </TableCell>
        <TableCell>
          {contract?.contract_value === undefined
            ? '-'
            : `$${currencyFormat(contract.contract_value)}`}
        </TableCell>
        <TableCell>{contract?.start_date || '-'}</TableCell>
        <TableCell>{contract?.end_date || '-'}</TableCell>
        <TableCell mw="110px" rightAlign={true}>
          {contract ? (
            <Pencil
              className="edit-icon"
              onClick={() => handleContractEdit(contract?.id || 0)}
            />
          ) : null}
          {contract && contract.has_preview ? (
            <Paper
              style={{ marginLeft: '12px' }}
              className="preview-icon"
              onClick={() => handlePDFPreview(contract.id)}
            />
          ) : null}
          {contract ? (
            <Archive
              className="archive-icon"
              style={{ marginLeft: '12px' }}
              onClick={() => handleArchive(contract.id)}
            />
          ) : null}
        </TableCell>
      </TableRow>
      {showDetails && (
        <>
          <ContractMoreDetail app={app} />
          <ContractAgreements
            contracts={contracts.filter((c) => getAgreements(c))}
            handleContractEdit={handleContractEdit}
            handlePDFPreview={handlePDFPreview}
            handleArchive={handleArchive}
          />
          <ContractsAllPerTool
            contracts={contracts.filter((c) => !getAgreements(c))}
            handleContractEdit={handleContractEdit}
            handlePDFPreview={handlePDFPreview}
            handleArchive={handleArchive}
          />
        </>
      )}
    </>
  )
}

export const ContractMoreDetail = ({ app }: { app?: Application }) => (
  <SubTable>
    <SubTableCell colSpan={15}>
      <table cellSpacing={0} style={{ width: '100%' }}>
        <tbody>
          <SubTableRow>
            <TitleCell>More Detail</TitleCell>
            <TableCell>
              <table cellSpacing={0} style={{ width: '100%' }}>
                <SubTableHead>
                  <SubTableRow>
                    <SubTableHeading>Department</SubTableHeading>
                    <SubTableHeading>Users</SubTableHeading>
                    <SubTableHeading>Total Visits</SubTableHeading>
                    <SubTableHeading
                      style={{ width: '35px' }}
                    ></SubTableHeading>
                  </SubTableRow>
                </SubTableHead>
                <tbody>
                  <SubTableRow>
                    <TableCell>{app?.department || '-'}</TableCell>
                    <TableCell>{app?.num_users || '-'}</TableCell>
                    <TableCell>{app?.num_visits || '-'}</TableCell>
                    <SubTableCell style={{ width: '35px' }}></SubTableCell>
                  </SubTableRow>
                </tbody>
              </table>
            </TableCell>
          </SubTableRow>
        </tbody>
      </table>
    </SubTableCell>
  </SubTable>
)

const ContractAgreements = ({
  contracts,
  handleContractEdit,
  handlePDFPreview,
  handleArchive,
}: {
  contracts: Contract[]
  handleContractEdit: (contract_id: number) => void
  handlePDFPreview: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
}) =>
  contracts &&
  contracts.length > 0 && (
    <SubTable>
      <SubTableCell colSpan={15}>
        <div
          style={{
            display: 'flex',
            borderTop: `1px solid ${theme.color.border}`,
          }}
        >
          <div
            style={{
              padding: '24px 48px',
              width: '400px',
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            Agreements & InfoSec
            <span
              style={{ fontWeight: '400', fontSize: '14px' }}
            >{` (${contracts.length})`}</span>
          </div>
          <table cellSpacing={0} style={{ width: '100%' }}>
            <SubTableHead>
              <SubTableRow>
                <SubTableHeading>Document</SubTableHeading>
                <SubTableHeading>Signed Date</SubTableHeading>
                <SubTableHeading
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100px',
                  }}
                >
                  <span>Expiry Date</span>
                  <SortArrow />
                </SubTableHeading>
                <SubTableHeading>Receiver Email</SubTableHeading>
                <SubTableHeading style={{ width: '110px' }}></SubTableHeading>
              </SubTableRow>
            </SubTableHead>
            <tbody>
              {contracts &&
                contracts.map((c, i) => (
                  <SubTableRow key={i}>
                    <TableCell>{`${c.type} - ${c.plan_name}` || '-'}</TableCell>
                    <TableCell>{c.start_date || '-'}</TableCell>
                    <TableCell>{c.end_date || '-'}</TableCell>
                    <TableCell>{c.receiver_email || '-'}</TableCell>
                    <TableCell
                      mw="110px"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '12px',
                      }}
                    >
                      {c ? (
                        <Pencil
                          className="edit-icon"
                          onClick={() => handleContractEdit(c.id)}
                        />
                      ) : null}
                      {c && c.has_preview ? (
                        <Paper onClick={() => handlePDFPreview(c.id)} />
                      ) : null}
                      <Archive onClick={() => handleArchive(c.id)} />
                    </TableCell>
                    <SubTableCell style={{ width: '15px' }}></SubTableCell>
                  </SubTableRow>
                ))}
            </tbody>
          </table>
        </div>
      </SubTableCell>
    </SubTable>
  )
const ContractsAllPerTool = ({
  contracts,
  handleContractEdit,
  handlePDFPreview,
  handleArchive,
}: {
  contracts: Contract[]
  handleContractEdit: (contract_id: number) => void
  handlePDFPreview: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
}) =>
  contracts &&
  contracts.length > 0 && (
    <SubTable>
      <SubTableCell colSpan={15}>
        <div
          style={{
            display: 'flex',
            borderTop: `1px solid ${theme.color.border}`,
          }}
        >
          <div
            style={{
              padding: '24px 48px',
              width: '400px',
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            All Contracts
            <span
              style={{ fontWeight: '400', fontSize: '14px' }}
            >{` (${contracts.length})`}</span>
          </div>
          <table cellSpacing={0} style={{ width: '100%' }}>
            <SubTableHead>
              <SubTableRow>
                <SubTableHeading>Plan</SubTableHeading>
                <SubTableHeading>Paid Licenses</SubTableHeading>
                <SubTableHeading>Contract Value</SubTableHeading>
                <SubTableHeading>Start Date</SubTableHeading>
                <SubTableHeading
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100px',
                  }}
                >
                  <span>End Date</span>
                  <SortArrow />
                </SubTableHeading>
                <SubTableHeading>Receiver Email</SubTableHeading>
                <SubTableHeading style={{ width: '110px' }}></SubTableHeading>
              </SubTableRow>
            </SubTableHead>
            <tbody>
              {contracts &&
                contracts.map((c, i) => (
                  <SubTableRow key={i}>
                    <TableCell>{c.plan_name || '-'}</TableCell>
                    <TableCell>{c.paid_licenses || 'N/A'}</TableCell>
                    <TableCell>
                      {c.contract_value === undefined
                        ? '-'
                        : `$${currencyFormat(c.contract_value)}`}
                    </TableCell>
                    <TableCell>{c.start_date || '-'}</TableCell>
                    <TableCell>{c.end_date || '-'}</TableCell>
                    <TableCell>{c.receiver_email || '-'}</TableCell>
                    <TableCell
                      mw="110px"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '12px',
                      }}
                    >
                      {c ? (
                        <Pencil
                          className="edit-icon"
                          onClick={() => handleContractEdit(c.id)}
                        />
                      ) : null}
                      {c && c.has_preview ? (
                        <Paper onClick={() => handlePDFPreview(c.id)} />
                      ) : null}
                      <Archive onClick={() => handleArchive(c.id)} />
                    </TableCell>
                    <SubTableCell style={{ width: '15px' }}></SubTableCell>
                  </SubTableRow>
                ))}
            </tbody>
          </table>
        </div>
      </SubTableCell>
    </SubTable>
  )

export default ActiveContractsPage
