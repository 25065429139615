import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Loading from '../assets/Loading'
import OverviewTableIcon from '../assets/OverviewTableIcon'
import Button, { GhostButton } from '../components/Button'
import Dropdown from '../components/Dropdown'
import {
  ApplicationCell,
  ArrowDownCell,
  DepartmentCell,
  OwnerCell,
  SliderCell,
  SortOrder,
  SortableHeader,
  SubTable,
  SubTableCell,
  SubTableHead,
  SubTableHeading,
  SubTableRow,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  TitleCell,
} from '../components/Table'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { UserType } from '../Users'
import {
  currencyFormat,
  backendRequest,
  addImpersonationParams,
  getRangeBudget,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import { getUserName } from '../utils/users'
import PlusCircle from '../assets/PlusCircle'
import MinusCircle from '../assets/MinusCircle'
import DownloadIcon from '../assets/Download'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ComplianceCerts } from './ComplianceCerts'
import { GetDefaultDateOptions } from '../utils/dates'

const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 0;
  position: sticky;
  top: 77px;
  background: ${theme.color.backgroudDefault};
  z-index: 5;
`

enum TableSort {
  APPLICATION = 'application',
  DEPARTMENT = 'department',
  USERS = 'users',
  VISITS = 'visits',
  TRANSACTION_AMOUNT = 'transaction_amount',
  TRANSACTION_DATE = 'transaction_date',
}

export type Application = {
  id: number
  name: string
  logo: string
  category: string
  url: string
  department: string
  pricing_model: string
  app_owner: string
  procurement_owner: string
  num_users: number
  num_visits: number
  spend_amount: number
  spend_date: string
  spend_breakdown: SpendBreakdown[]
  children: Application[]
  approved: boolean
}

type SpendBreakdown = {
  spend_amount: number
  spend_date: string
}

export type Budget = {
  saas_tool_id: number | null
  department_id: number | null
  amount: number
  budget_start_date: string
  budget_end_date: string
}

export type Budgets = {
  app_budgets: Budget[] | null
  department_budgets: Budget[] | null
}

enum PayFilter {
  ALL = 'All',
  PAID = 'Paid',
  UNPAID = 'Unpaid',
}

export const ApplicationsTable = () => {
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()
  const { budgetOverview } = useFlags()
  const dropdownOptions = GetDefaultDateOptions()

  const [apps, setApps] = useState<Application[] | undefined>(undefined)
  const [budgets, setBudgets] = useState<Budget[] | undefined>(undefined)
  const [users, setUsers] = useState<UserType[] | undefined>(undefined)
  const [departments, setDepartments] = useState<string[] | undefined>(
    undefined
  )
  const [payFilter, setPayFilter] = useState<PayFilter>(PayFilter.ALL)
  const [dateFilter, setDateFilter] = useState<string>(dropdownOptions[0][0])
  const [sortAttr, setSortAttr] = useState<TableSort>(TableSort.APPLICATION)
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC)

  const onRangeChange = (selected: string) => {
    setDateFilter(selected)
  }

  const sort = (applications: Application[] | undefined) => {
    if (!applications) {
      return applications
    }
    const sorted = [...applications]
    switch (sortAttr) {
      case TableSort.APPLICATION:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.name.localeCompare(b.name))
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.name.localeCompare(a.name))
        break
      case TableSort.DEPARTMENT:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.department.localeCompare(b.department))
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.department.localeCompare(a.department))
        break
      case TableSort.USERS:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.num_users - b.num_users)
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.num_users - a.num_users)
        break
      case TableSort.VISITS:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.num_visits - b.num_visits)
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.num_visits - a.num_visits)
        break
      case TableSort.TRANSACTION_DATE:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.spend_date.localeCompare(b.spend_date))
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.spend_date.localeCompare(a.spend_date))
        break
      case TableSort.TRANSACTION_AMOUNT:
      default:
        sortOrder === SortOrder.ASC &&
          sorted.sort((a, b) => a.spend_amount - b.spend_amount)
        sortOrder === SortOrder.DESC &&
          sorted.sort((a, b) => b.spend_amount - a.spend_amount)
    }
    return sorted
  }

  const sortedApps = useMemo((): Application[] | undefined => {
    return sort(apps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAttr, sortOrder])

  useEffect(() => {
    setApps(undefined)
    const getApps = async () => {
      const filter = dateFilter.toLowerCase().replaceAll(' ', '_')
      const reqPath = addImpersonationParams(
        `/usage/all?filter=${filter}`,
        impersonate,
        reqOrgId,
        reqUserId,
        true
      )
      const appUsage = await backendRequest(reqPath)
      if (appUsage.error) {
        setApps([])
      }
      const sorted = sort(appUsage.usage)
      setApps(sorted || [])
      setBudgets(appUsage.budgets?.app_budgets ?? [])
    }
    getApps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter, reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    const getUsers = async () => {
      const reqPath = addImpersonationParams(
        `/user_names`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch users`, ToastType.ERROR)
        setUsers([])
      }
      setUsers(resp)
    }
    getUsers()
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    const getDepartments = async () => {
      const reqPath = addImpersonationParams(
        `/departments`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch departments`, ToastType.ERROR)
        setDepartments([])
      }
      setDepartments(resp)
    }
    getDepartments()
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    setApps(sortedApps)
  }, [sortedApps])

  const onSortChange = (selected: TableSort) => {
    if (!apps) return
    if (sortAttr === selected) {
      setSortOrder(sortOrder * -1)
    } else {
      setSortOrder(SortOrder.ASC)
      setSortAttr(selected)
    }
  }

  const onOwnerChange = (appId: number, userHash: string) => {
    if (!apps) return
    let userName = ''
    for (const user of users || []) {
      if (user.hash === userHash) {
        userName = getUserName(user)
        break
      }
    }
    for (const app of apps || []) {
      if (app.id === appId) {
        app.app_owner = userName
      }
      for (const child of app.children) {
        if (child.id === appId) {
          child.app_owner = userName
        }
      }
    }
    setApps([...apps])
  }

  const onDepartmentChange = (appId: number, department: string) => {
    if (!apps) return
    for (const app of apps || []) {
      if (app.id === appId) {
        app.department = department
      }
      for (const child of app.children) {
        if (child.id === appId) {
          child.department = department
        }
      }
    }
    setApps([...apps])
  }

  const onApprove = (appId: number, checked: boolean) => {
    if (!apps) return
    for (const app of apps) {
      if (app.id === appId) {
        app.approved = checked
      }
      for (const child of app.children) {
        if (child.id === appId) {
          child.approved = checked
        }
      }
    }
    setApps([...apps])
  }

  const downloadOverview = () => {
    if (!apps) return
    const csv = apps.map((app) => {
      if (budgets && budgets.length > 0) {
        const budget = budgets.find((b) => b.saas_tool_id === app.id)
        const monthlyBudget = getRangeBudget(dateFilter, budget)
        return `"${app.name}","${app.department}","${app.category}","${app.pricing_model}","${app.url}","${app.app_owner}","${app.num_users}","${new Intl.NumberFormat().format(app.num_visits)}","$${currencyFormat(app.spend_amount)}","${app.spend_date}","$${currencyFormat(monthlyBudget)}"`
      }
      return `"${app.name}","${app.department}","${app.category}","${app.pricing_model}","${app.url}","${app.app_owner}","${app.num_users}","${new Intl.NumberFormat().format(app.num_visits)}","$${currencyFormat(app.spend_amount)}","${app.spend_date}"`
    })
    if (budgets && budgets.length > 0) {
      csv.unshift(
        `Application,Department,Category,Pricing Model,Link,App Owner,Users,Visits,Total Transaction Amount (${dateFilter}),Last Transaction Date,Budget`
      )
    } else {
      csv.unshift(
        `Application,Department,Category,Pricing Model,Link,App Owner,Users,Visits,Total Transaction Amount (${dateFilter}),Last Transaction Date`
      )
    }
    const csvData = csv.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `diminish-overview-${new Date().toISOString()}.csv`
    a.click()
  }

  const displayApps = []
  for (const app of apps || []) {
    if (payFilter === PayFilter.PAID && app.spend_amount <= 0) {
      continue
    }
    if (payFilter === PayFilter.UNPAID && app.spend_amount > 0) {
      continue
    }
    displayApps.push(app)
  }

  return (
    <div style={{ minHeight: '700px' }}>
      <HeaderContainer>
        <HeadingMedium color="textDefault">
          Applications
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {displayApps.length > 0 && ` (${displayApps.length})`}
          </span>
        </HeadingMedium>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Dropdown
            options={[Object.values(PayFilter)]}
            defaultValue={payFilter}
            onSelect={(selected: string) => setPayFilter(selected as PayFilter)}
          />
          <Dropdown
            options={dropdownOptions}
            defaultValue={dropdownOptions[0][0]}
            onSelect={onRangeChange}
          />
          <GhostButton onClick={downloadOverview} style={{ padding: '12px' }}>
            <DownloadIcon />
          </GhostButton>
        </div>
      </HeaderContainer>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow
            style={{
              position: 'sticky',
              top: '159px',
              background: theme.color.backgroudDefault,
              zIndex: 3,
            }}
          >
            <TableCell mw="5px"></TableCell>
            <SortableHeader
              isSorted={sortAttr === TableSort.APPLICATION}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.APPLICATION)}
              mw="160px"
            >
              Application
            </SortableHeader>
            <SortableHeader
              isSorted={sortAttr === TableSort.DEPARTMENT}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.DEPARTMENT)}
              mw="70px"
            >
              Department
            </SortableHeader>
            <TableCell w="200px">App Owner</TableCell>
            <TableCell w="200px">Procurement Owner</TableCell>
            {!budgetOverview ? (
              <>
                <SortableHeader
                  isSorted={sortAttr === TableSort.USERS}
                  sortOrder={sortOrder}
                  onClick={() => onSortChange(TableSort.USERS)}
                  mw="30px"
                >
                  Users
                </SortableHeader>
                <SortableHeader
                  isSorted={sortAttr === TableSort.VISITS}
                  sortOrder={sortOrder}
                  onClick={() => onSortChange(TableSort.VISITS)}
                  mw="40px"
                >
                  Visits
                </SortableHeader>
              </>
            ) : (
              <></>
            )}
            <SortableHeader
              isSorted={sortAttr === TableSort.TRANSACTION_AMOUNT}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.TRANSACTION_AMOUNT)}
              mw="70px"
            >
              Transaction Amount
            </SortableHeader>
            <SortableHeader
              isSorted={sortAttr === TableSort.TRANSACTION_DATE}
              sortOrder={sortOrder}
              onClick={() => onSortChange(TableSort.TRANSACTION_DATE)}
              mw="50px"
            >
              Transaction Date
            </SortableHeader>
            {budgetOverview ? (
              <>
                <TableCell mw="50px">Budget Amount</TableCell>
                <TableCell mw="50px">BvA</TableCell>
              </>
            ) : (
              <></>
            )}
            <TableCell mw="25px">Approved</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apps === undefined ? (
            <TableRow style={{ width: '100%', height: '50px' }}>
              <TableCell colSpan={15}>
                <Loading />
              </TableCell>
            </TableRow>
          ) : displayApps.length === 0 ? (
            <EmptyAppsTableBody colSpan={15} />
          ) : (
            displayApps.map((app, i) => (
              <AppTableRow
                key={i}
                app={app}
                users={users}
                departments={departments}
                onApprove={onApprove}
                onOwnerChange={onOwnerChange}
                onDepartmentChange={onDepartmentChange}
                budget={budgets?.find((b) => b.saas_tool_id === app.id)}
                dateFilter={dateFilter}
              />
            ))
          )}
        </TableBody>
      </TableWrapper>
    </div>
  )
}

const AppTableRow = ({
  app,
  users,
  departments,
  onApprove,
  onOwnerChange,
  onDepartmentChange,
  budget,
  dateFilter,
}: {
  app: Application
  users: UserType[] | undefined
  departments: string[] | undefined
  onApprove: (appId: number, checked: boolean) => void
  onOwnerChange: (appId: number, userHash: string) => void
  onDepartmentChange: (appId: number, department: string) => void
  budget: Budget | undefined
  dateFilter: string
}) => {
  const [showChildren, setShowChildren] = useState(false)

  const { budgetOverview } = useFlags()
  const monthlyBudget = getRangeBudget(dateFilter, budget)

  return (
    <>
      <TableRow style={{ boxShadow: '' }}>
        <ArrowDownCell
          onClick={() => setShowChildren(!showChildren)}
          open={showChildren}
        />
        <ApplicationCell id={app.id} logo={app.logo} name={app.name} />
        <DepartmentCell
          appId={app.id}
          options={departments || []}
          defaulOption={app.department}
          onChange={onDepartmentChange}
        />
        <OwnerCell
          appId={app.id}
          options={users || []}
          defaulOption={app.app_owner}
          onChange={onOwnerChange}
          ownerType="app"
        />
        <OwnerCell
          appId={app.id}
          options={users || []}
          defaulOption={app.procurement_owner}
          ownerType="procurement"
        />
        {!budgetOverview ? (
          <>
            <TableCell>{app.num_users > 0 ? app.num_users : '-'}</TableCell>
            <TableCell>{app.num_visits > 0 ? app.num_visits : '-'}</TableCell>
          </>
        ) : (
          <></>
        )}
        <TableCell>${currencyFormat(app.spend_amount)}</TableCell>
        <TableCell mw="50px">
          {app.spend_date ? app.spend_date : <p>-</p>}
        </TableCell>
        {budgetOverview ? (
          <>
            <TableCell>${currencyFormat(monthlyBudget)}</TableCell>
            <BudgetDiff budgetDiff={monthlyBudget - app.spend_amount} />
          </>
        ) : (
          <></>
        )}
        <SliderCell appId={app.id} state={app.approved} onClick={onApprove} />
      </TableRow>
      {showChildren && (
        <SubRows
          app={app}
          spendBreakdown={app.spend_breakdown}
          apps={app.children}
          users={users}
          onOwnerChange={onOwnerChange}
          budget={budget}
          dateFilter={dateFilter}
        />
      )}
    </>
  )
}

const SubRows = ({
  spendBreakdown,
  app,
  apps,
  users,
  onOwnerChange,
  budget,
  dateFilter,
}: {
  spendBreakdown: SpendBreakdown[]
  app: Application
  apps: Application[]
  users: UserType[] | undefined
  onOwnerChange: (appId: number, userHash: string) => void
  budget: Budget | undefined
  dateFilter: string
}) => {
  return (
    <>
      <SubTable>
        <SubTableCell colSpan={15}>
          <AppMoreInfo app={app} budget={budget} dateFilter={dateFilter} />
          {apps.length > 0 && (
            <ChildRows
              apps={apps}
              users={users}
              onOwnerChange={onOwnerChange}
            />
          )}
          <ComplianceCerts app={app} />
          {spendBreakdown.length > 1 && (
            <SpendBreakdownRows spendBreakdown={spendBreakdown} />
          )}
        </SubTableCell>
      </SubTable>
    </>
  )
}

export const AppMoreInfo = ({
  app,
  budget,
  dateFilter,
}: {
  app: Application
  budget: Budget | undefined
  dateFilter: string
}) => {
  const { budgetOverview } = useFlags()

  const monthlyBudget = getRangeBudget(dateFilter, budget)
  return (
    <table cellSpacing={0} style={{ width: '100%' }}>
      <tbody>
        <SubTableRow>
          <TitleCell>More Info</TitleCell>
          <TableCell>
            <table cellSpacing={0} style={{ width: '100%' }}>
              <SubTableHead>
                <SubTableRow>
                  <SubTableHeading>Category</SubTableHeading>
                  <SubTableHeading>Source</SubTableHeading>
                  <SubTableHeading>Pricing Model</SubTableHeading>
                  {budget && !budgetOverview ? (
                    <>
                      <SubTableHeading>Budget</SubTableHeading>
                      <SubTableHeading>Budget Difference</SubTableHeading>
                    </>
                  ) : (
                    <></>
                  )}
                  <SubTableHeading style={{ width: '35px' }}></SubTableHeading>
                </SubTableRow>
              </SubTableHead>
              <tbody>
                <SubTableRow>
                  <TableCell>{app.category ? app.category : '-'}</TableCell>
                  <SubTableCell>
                    <a
                      href={`https://${app.url}`}
                      target="_blank"
                      style={{
                        color: theme.color.textSubdued,
                        fontWeight: 500,
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      Link
                    </a>
                  </SubTableCell>
                  <TableCell>
                    {app.pricing_model ? app.pricing_model : '-'}
                  </TableCell>
                  {budget && !budgetOverview ? (
                    <>
                      <SubTableCell>
                        ${currencyFormat(monthlyBudget)}
                      </SubTableCell>
                      <BudgetDiff
                        budgetDiff={monthlyBudget - app.spend_amount}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <SubTableCell style={{ width: '35px' }}></SubTableCell>
                </SubTableRow>
              </tbody>
            </table>
          </TableCell>
        </SubTableRow>
      </tbody>
    </table>
  )
}

const BudgetDiff = ({ budgetDiff }: { budgetDiff: number }) => {
  const cellStyle = {
    color: budgetDiff >= 0 ? theme.color.ChipGreen : theme.color.ChipRed,
    display: 'flex',
    alignItems: 'center',
  }
  return (
    <TableCell mw="50px">
      <div style={cellStyle}>
        {budgetDiff >= 0 ? <PlusCircle /> : <MinusCircle />}$
        {currencyFormat(Math.abs(budgetDiff))}
      </div>
    </TableCell>
  )
}

const ChildRows = ({
  apps,
  users,
  onOwnerChange,
}: {
  apps: Application[]
  users: UserType[] | undefined
  onOwnerChange: (appId: number, userHash: string) => void
}) => {
  return (
    <table cellSpacing={0} style={{ width: '100%' }}>
      <tbody>
        <SubTableRow>
          <TitleCell rowSpan={apps.length + 1}>
            Products{' '}
            <span style={{ fontWeight: '400', fontSize: '14px' }}>
              ({apps.length})
            </span>
          </TitleCell>
          <TableCell>
            <table cellSpacing={0} style={{ width: '100%' }}>
              <SubTableHead>
                <SubTableRow>
                  <SubTableHeading>Application</SubTableHeading>
                  <SubTableHeading style={{ width: '200px' }}>
                    App Owner
                  </SubTableHeading>
                  <SubTableHeading style={{ width: '200px' }}>
                    Procurement Owner
                  </SubTableHeading>
                  <SubTableHeading style={{ maxWidth: '50px' }}>
                    Users
                  </SubTableHeading>
                  <SubTableHeading style={{ maxWidth: '50px' }}>
                    Visits
                  </SubTableHeading>
                  <SubTableHeading style={{ width: '35px' }}></SubTableHeading>
                </SubTableRow>
              </SubTableHead>
              <tbody>
                {apps.map((app, i) => (
                  <SubTableRow key={i}>
                    <SubTableCell>
                      <a
                        href={`/product/${app.id}`}
                        style={{
                          color: 'inherit',
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                        }}
                      >
                        {app.name}
                      </a>
                    </SubTableCell>
                    <OwnerCell
                      appId={app.id}
                      options={users || []}
                      defaulOption={app.app_owner}
                      onChange={onOwnerChange}
                      ownerType="app"
                    />
                    <OwnerCell
                      appId={app.id}
                      options={users || []}
                      defaulOption={app.procurement_owner}
                      onChange={onOwnerChange}
                      ownerType="procurement"
                    />
                    <TableCell mw="50px">
                      {app.num_users > 0 ? app.num_users : '-'}
                    </TableCell>
                    <TableCell>
                      {app.num_visits > 0 ? app.num_visits : '-'}
                    </TableCell>
                    <SubTableCell style={{ width: '35px' }}></SubTableCell>
                  </SubTableRow>
                ))}
              </tbody>
            </table>
          </TableCell>
        </SubTableRow>
      </tbody>
    </table>
  )
}

const SpendBreakdownRows = ({
  spendBreakdown,
}: {
  spendBreakdown: SpendBreakdown[]
}) => {
  return (
    <table cellSpacing={0} style={{ width: '100%' }}>
      <tbody>
        <SubTableRow>
          <TitleCell rowSpan={spendBreakdown.length + 1}>
            Spend Breakdown{' '}
            <span style={{ fontWeight: '400', fontSize: '14px' }}>
              ({spendBreakdown.length})
            </span>
          </TitleCell>
          <TableCell>
            <table cellSpacing={0} style={{ width: '100%' }}>
              <SubTableHead>
                <SubTableRow>
                  <SubTableHeading>Amount</SubTableHeading>
                  <SubTableHeading>Date</SubTableHeading>
                  <SubTableHeading style={{ width: '5px' }}></SubTableHeading>
                </SubTableRow>
              </SubTableHead>
              <tbody>
                {spendBreakdown.map((spend: SpendBreakdown, i: number) => (
                  <SubTableRow key={i}>
                    <SubTableCell>
                      ${currencyFormat(spend.spend_amount)}
                    </SubTableCell>
                    <SubTableCell>{spend.spend_date}</SubTableCell>
                    <SubTableCell style={{ width: '5px' }}></SubTableCell>
                  </SubTableRow>
                ))}
              </tbody>
            </table>
          </TableCell>
        </SubTableRow>
      </tbody>
    </table>
  )
}

const EmptyAppsTableBody = ({ colSpan }: { colSpan: number }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <div
        style={{
          borderTop: `1px solid ${theme.color.border}`,
          borderBottom: `1px solid ${theme.color.border}`,
          margin: '15px 0',
          padding: '15px 0',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <OverviewTableIcon />
        <HeadingMedium color="textDefault">
          This is where you see your usage and spend data
        </HeadingMedium>
        <HeadingSmallest>
          Add your integrations to view the data.
        </HeadingSmallest>
        <Button onClick={() => (window.location.href = '/integrations')}>
          Set Up Your Integrations
        </Button>
      </div>
    </TableCell>
  </TableRow>
)
